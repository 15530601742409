import authenticationIcon from '@/assets/images/icons/node/Authentication.svg';
import baseIcon from '@/assets/images/icons/node/Base.svg';
import carouselIcon from '@/assets/images/icons/node/Carousel.svg';
import containerIcon from '@/assets/images/icons/node/Container.svg';
import addNotificationIcon from '@/assets/images/icons/node/add-notification.svg';
import decryptIcon from '@/assets/images/icons/node/Decrypt.svg';
import defineSummaryIcon from '@/assets/images/icons/node/DefineSummary.svg';
import endIcon from '@/assets/images/icons/node/End.svg';
import fileUploadIcon from '@/assets/images/icons/node/FileUpload.svg';
import getUserInformationIcon from '@/assets/images/icons/node/GetUserInformation.svg';
import getUserPoliciesIcon from '@/assets/images/icons/node/GetUserPolicies.svg';
import getUserVehiclesIcon from '@/assets/images/icons/node/GetUserVehicles.svg';
import InputAutocomplete from '@/assets/images/icons/node/InputAutocomplete.svg';
import inputChoiceIcon from '@/assets/images/icons/node/InputChoice.svg';
import inputCurrencyIcon from '@/assets/images/icons/node/InputCurrency.svg';
import inputDateIcon from '@/assets/images/icons/node/InputDate.svg';
import InputFromCarouselList from '@/assets/images/icons/node/InputFromCarouselList.svg';
import inputMultipleChoiceIcon from '@/assets/images/icons/node/InputMultipleChoice.svg';
import inputTextIcon from '@/assets/images/icons/node/InputText.svg';
import inputTimeIcon from '@/assets/images/icons/node/InputTime.svg';
import itemListIcon from '@/assets/images/icons/node/ItemList.svg';
import messageIcon from '@/assets/images/icons/node/Message.svg';
import ratingStarsIcon from '@/assets/images/icons/node/RatingStars.svg';
import sendDataIcon from '@/assets/images/icons/node/SendData.svg';
import setStatisticsIcon from '@/assets/images/icons/node/SetStatistics.svg';
import setValueIcon from '@/assets/images/icons/node/SetValue.svg';
import splitterIcon from '@/assets/images/icons/node/Splitter.svg';
import startIcon from '@/assets/images/icons/node/Start.svg';
import summaryIcon from '@/assets/images/icons/node/Summary.svg';
import userPoliciesIcon from '@/assets/images/icons/node/UserPolicies.svg';
import userVehiclesIcon from '@/assets/images/icons/node/UserVehicles.svg';
import longListIcon from '@/assets/images/icons/node/LongList.svg';
import inputPhoneIcon from '@/assets/images/icons/node/InputPhone.svg';
import overviewIcon from '@/assets/images/icons/node/Overview.svg';
import saveDraftIcon from '@/assets/images/icons/node/SaveDraft.svg';
import loadDraftIcon from '@/assets/images/icons/node/LoadDraft.svg';
import updateDraftMetadata from '@/assets/images/icons/node/UpdateDraftMetadata.svg';

import authenticationInvertedIcon from '@/assets/images/icons/node/inverted/Authentication.svg';
import defineSummaryInvertedIcon from '@/assets/images/icons/node/inverted/DefineSummary.svg';
import getUserInformationInvertedIcon from '@/assets/images/icons/node/inverted/GetUserInformation.svg';
import getUserPoliciesInvertedIcon from '@/assets/images/icons/node/inverted/GetUserPolicies.svg';
import getUserVehiclesInvertedIcon from '@/assets/images/icons/node/inverted/GetUserVehicles.svg';
import sendDataInvertedIcon from '@/assets/images/icons/node/inverted/SendData.svg';
import setStatisticsInvertedIcon from '@/assets/images/icons/node/inverted/SetStatistics.svg';

export enum NodeDesignType {
  Base = 'Base',
  Default = 'Default',
  Baltic = 'Baltic',
  InputText = 'InputText',
  InputTextArea = 'InputTextArea',
  InputBankAccount = 'InputBankAccount',
  Message = 'Message',
  InputChoice = 'InputChoice',
  InputMultipleChoice = 'InputMultipleChoice',
  InputDate = 'InputDate',
  FileUpload = 'FileUpload',
  RatingStars = 'RatingStars',
  InputTime = 'InputTime',
  Summary = 'Summary',
  Splitter = 'Splitter',
  Container = 'Container',
  Section = 'Section',
  DeadEnd = 'DeadEnd',
  End = 'End',
  Carousel = 'Carousel',
  Start = 'Start',
  SetValue = 'SetValue',
  DefineSummary = 'DefineSummary',
  SendData = 'SendData',
  Authentication = 'Authentication',
  EmployeeAuthentication = 'EmployeeAuthentication',
  SilentAuthentication = 'SilentAuthentication',
  DecryptValue = 'DecryptValue',
  InputCurrency = 'InputCurrency',
  ItemList = 'ItemList',
  SetStatistics = 'SetStatistics',
  WebchatGetUserInformation = 'WebchatGetUserInformation',
  TellusAndWebchatGetUserInformation = 'TellusAndWebchatGetUserInformation',
  Input = 'Input',
  InputSsn = 'InputSsn',
  WebchatGetUserVehicles = 'WebchatGetUserVehicles',
  TellUsAndWebchatGetUserVehicles = 'TellUsAndWebchatGetUserVehicles',
  WebchatShowUserVehicles = 'WebchatShowUserVehicles',
  TellUsAndWebchatShowUserVehicles = 'TellUsAndWebchatShowUserVehicles',
  InputChoiceFromCarousel = 'InputChoiceFromCarousel',
  WeakAuthentication = 'WeakAuthentication',
  LongList = 'LongList',
  GetPolicyInformation = 'GetPolicyInformation',
  InputPhone = 'InputPhone',
  FraudScore = 'FraudScore',
  RecommendedServices = 'RecommendedServices',
  Overview = 'Overview',
  SaveDraft = 'SaveDraft',
  LoadDraft = 'LoadDraft',
  UpdateDraftMetadata = 'UpdateDraftMetadata',
  GetSubmissionRelation = 'GetSubmissionRelation',
  TellUsClassifierDropdown = 'TellUsClassifierDropdown',
  ClassifierDropdown = 'ClassifierDropdown',
  CommonFrontenSendData = 'CommonFrontenSendData',
  FI_WorkersComp_GetUserInformation = 'FI_WorkersComp_GetUserInformation',
  StickyNote = 'StickyNote',
  WebchatGetUserPolicies = 'WebchatGetUserPolicies',
  TellusGetUserPolicies = 'TellusGetUserPolicies',
  TellusAndWebchatGetUserPolicies = 'TellusAndWebchatGetUserPolicies',
  TellusShowPolicies = 'TellusShowPolicies',
  TellusAndWebchatShowPolicies = 'TellusAndWebchatShowPolicies',
  WebchatShowPolicies = 'WebchatShowPolicies',
  GetPrefillStorage = 'GetPrefillStorage',
  SetPrefillStorage = 'SetPrefillStorage',
  TellusSendData = 'TellusSendData',
  TellusGetCustomerInformationVrf = 'TellusGetCustomerInformationVrf',
  TellusDuplicateVrfClaimCheck = 'TellusDuplicateVrfClaimCheck',
  GetUserWorkshopRoles = 'GetUserWorkshopRoles',
}

import { Colors } from '@/constants';

enum NodeVariation {
  DEFAULT = 'DEFAULT',
  SERVICE = 'SERVICE',
  TECHNICAL = 'TECHNICAL',
  STICKY_NOTE = 'STICKY_NOTE',
}

interface NodeVariationStyles {
  primaryFill: string;
  secondaryFill: string;
  textColor: string;
}

const nodeVariation: Record<NodeVariation, NodeVariationStyles> = {
  [NodeVariation.DEFAULT]: {
    primaryFill: Colors.BEIGE_100,
    secondaryFill: Colors.BEIGE_100,
    textColor: Colors.BROWN_200,
  },
  [NodeVariation.SERVICE]: {
    primaryFill: '#848D87',
    secondaryFill: '#848D87',
    textColor: Colors.BEIGE_100,
  },
  [NodeVariation.TECHNICAL]: {
    primaryFill: '#D6D9E0',
    secondaryFill: '#D6D9E0',
    textColor: Colors.BROWN_200,
  },
  [NodeVariation.STICKY_NOTE]: {
    primaryFill: '#f1f58f',
    secondaryFill: '#D6D9E0',
    textColor: Colors.BROWN_200,
  },
};

export interface NodeDesign {
  markerColor: string;
  icon: string;
  invertedIcon?: string;
  variation: NodeVariationStyles;
  type: NodeDesignType;
  isWebchatNode: boolean;
  isTellusNode: boolean;
}

export const nodeDesigns: Record<NodeDesignType, NodeDesign> = {
  [NodeDesignType.Base]: {
    markerColor: 'transparent',
    icon: baseIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Base,
    isWebchatNode: false,
    isTellusNode: false,
  },
  [NodeDesignType.Default]: {
    markerColor: 'transparent',
    icon: baseIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Default,
    isWebchatNode: false,
    isTellusNode: false,
  },
  [NodeDesignType.Baltic]: {
    markerColor: '#4975ED',
    icon: baseIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Baltic,
    isWebchatNode: false,
    isTellusNode: false,
  },
  [NodeDesignType.InputText]: {
    markerColor: '#FF7714',
    icon: inputTextIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputText,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputTextArea]: {
    markerColor: '#FF2414',
    icon: inputTextIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputTextArea,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputBankAccount]: {
    markerColor: '#FF7714',
    icon: inputTextIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputBankAccount,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Message]: {
    markerColor: '#6494FF',
    icon: messageIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Message,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputChoice]: {
    markerColor: '#C906E3',
    icon: inputChoiceIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputChoice,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputMultipleChoice]: {
    markerColor: '#933519',
    icon: inputMultipleChoiceIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputMultipleChoice,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputDate]: {
    markerColor: '#4593B5',
    icon: inputDateIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputDate,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.FileUpload]: {
    markerColor: '#AF971D',
    icon: fileUploadIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.FileUpload,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.RatingStars]: {
    markerColor: '#F0C61D',
    icon: ratingStarsIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.RatingStars,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.InputTime]: {
    markerColor: '#1EBEAB',
    icon: inputTimeIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputTime,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Summary]: {
    markerColor: '#DEC091',
    icon: summaryIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Summary,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Splitter]: {
    markerColor: '#EB1E1E',
    icon: splitterIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.Splitter,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Container]: {
    markerColor: '#FFA396',
    icon: containerIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.Container,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Section]: {
    markerColor: '#40ff00',
    icon: addNotificationIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.Section,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.DeadEnd]: {
    markerColor: '#b4c9ad',
    icon: addNotificationIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.DeadEnd,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.End]: {
    markerColor: '#331E11',
    icon: endIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.End,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.Carousel]: {
    markerColor: '#762DB5',
    icon: carouselIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.Carousel,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.InputChoiceFromCarousel]: {
    markerColor: '#ff93ff',
    icon: InputFromCarouselList as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputChoiceFromCarousel,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.Start]: {
    markerColor: '#217331',
    icon: startIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.Start,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.SetValue]: {
    markerColor: '#959595',
    icon: setValueIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.SetValue,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.DefineSummary]: {
    markerColor: '#A06C38',
    icon: defineSummaryIcon as string,
    invertedIcon: defineSummaryInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.DefineSummary,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.SendData]: {
    markerColor: '#D3E9FF',
    icon: sendDataIcon as string,
    invertedIcon: sendDataInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.SendData,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.Authentication]: {
    markerColor: '#7DD99D',
    icon: authenticationIcon as string,
    invertedIcon: authenticationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.Authentication,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.EmployeeAuthentication]: {
    markerColor: '#7DD99D',
    icon: authenticationIcon as string,
    invertedIcon: authenticationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.EmployeeAuthentication,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WeakAuthentication]: {
    markerColor: '#7DD99D',
    icon: authenticationIcon as string,
    invertedIcon: authenticationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.WeakAuthentication,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.SilentAuthentication]: {
    markerColor: '#3DA21E',
    icon: authenticationIcon as string,
    invertedIcon: authenticationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.SilentAuthentication,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.DecryptValue]: {
    markerColor: '#565A77',
    icon: decryptIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.DecryptValue,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.InputCurrency]: {
    markerColor: '#ddb019',
    icon: inputCurrencyIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputCurrency,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.ItemList]: {
    markerColor: '#da7ee6',
    icon: itemListIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.ItemList,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.SetStatistics]: {
    markerColor: '#98D5FF',
    icon: setStatisticsIcon as string,
    invertedIcon: setStatisticsInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.SetStatistics,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WebchatGetUserInformation]: {
    markerColor: '#0E9F56',
    icon: getUserInformationIcon as string,
    invertedIcon: getUserInformationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.WebchatGetUserInformation,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.TellusAndWebchatGetUserInformation]: {
    markerColor: '#0E9F56',
    icon: getUserInformationIcon as string,
    invertedIcon: getUserInformationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusAndWebchatGetUserInformation,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WebchatShowPolicies]: {
    markerColor: '#11B70B',
    icon: userPoliciesIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.WebchatShowPolicies,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.TellusShowPolicies]: {
    markerColor: '#11B70B',
    icon: userPoliciesIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.TellusShowPolicies,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.TellusAndWebchatShowPolicies]: {
    markerColor: '#11B70B',
    icon: userPoliciesIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.TellusAndWebchatShowPolicies,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WebchatGetUserPolicies]: {
    markerColor: '#16BE57',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.WebchatGetUserPolicies,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.TellusGetUserPolicies]: {
    markerColor: '#16BE57',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusGetUserPolicies,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.TellusAndWebchatGetUserPolicies]: {
    markerColor: '#16BE57',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusAndWebchatGetUserPolicies,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.GetSubmissionRelation]: {
    markerColor: '#16BE57',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.WebchatGetUserPolicies,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.Input]: {
    markerColor: '#A5D86C',
    icon: InputAutocomplete as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Input,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.InputSsn]: {
    markerColor: '#A5D86C',
    icon: InputAutocomplete as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputSsn,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WebchatGetUserVehicles]: {
    markerColor: '#26C499',
    icon: getUserVehiclesIcon as string,
    invertedIcon: getUserVehiclesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.WebchatGetUserVehicles,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.TellUsAndWebchatGetUserVehicles]: {
    markerColor: '#26C499',
    icon: getUserVehiclesIcon as string,
    invertedIcon: getUserVehiclesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellUsAndWebchatGetUserVehicles,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.WebchatShowUserVehicles]: {
    markerColor: '#4AE8BD',
    icon: userVehiclesIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.WebchatShowUserVehicles,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.TellUsAndWebchatShowUserVehicles]: {
    markerColor: '#4AE8BD',
    icon: userVehiclesIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.TellUsAndWebchatShowUserVehicles,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.LongList]: {
    markerColor: '#831751',
    icon: longListIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.LongList,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.GetPolicyInformation]: {
    markerColor: '#16BE57',
    icon: getUserInformationIcon as string,
    invertedIcon: getUserInformationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.GetPolicyInformation,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.InputPhone]: {
    markerColor: '#B41F1F',
    icon: inputPhoneIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.InputPhone,
    isWebchatNode: true,
    isTellusNode: false,
  },
  [NodeDesignType.FraudScore]: {
    markerColor: '#f1c232',
    icon: setValueIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.FraudScore,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.RecommendedServices]: {
    markerColor: '#f1c232',
    icon: setValueIcon as string,
    variation: nodeVariation.TECHNICAL,
    type: NodeDesignType.RecommendedServices,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.Overview]: {
    markerColor: '#D8B24E',
    icon: overviewIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.Overview,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.SaveDraft]: {
    markerColor: '#8DBBD5',
    icon: saveDraftIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.SaveDraft,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.LoadDraft]: {
    markerColor: '#8DBBD5',
    icon: loadDraftIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.LoadDraft,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.UpdateDraftMetadata]: {
    markerColor: '#D2AB5F',
    icon: updateDraftMetadata as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.UpdateDraftMetadata,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.GetPrefillStorage]: {
    markerColor: '#8DBBD5',
    icon: loadDraftIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.GetPrefillStorage,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.SetPrefillStorage]: {
    markerColor: '#8DBBD5',
    icon: saveDraftIcon as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.SetPrefillStorage,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.TellUsClassifierDropdown]: {
    markerColor: '#A5D86C',
    icon: InputAutocomplete as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.TellUsClassifierDropdown,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.CommonFrontenSendData]: {
    markerColor: '#D3E9FF',
    icon: sendDataIcon as string,
    invertedIcon: sendDataInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.CommonFrontenSendData,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.FI_WorkersComp_GetUserInformation]: {
    markerColor: '#0E9F56',
    icon: getUserInformationIcon as string,
    invertedIcon: getUserInformationInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.FI_WorkersComp_GetUserInformation,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.ClassifierDropdown]: {
    markerColor: '#A5D86C',
    icon: InputAutocomplete as string,
    variation: nodeVariation.DEFAULT,
    type: NodeDesignType.ClassifierDropdown,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.StickyNote]: {
    markerColor: '#f1f58f',
    icon: InputAutocomplete as string,
    variation: nodeVariation.STICKY_NOTE,
    type: NodeDesignType.StickyNote,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.TellusSendData]: {
    markerColor: '#D3E9FF',
    icon: sendDataIcon as string,
    invertedIcon: sendDataInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusSendData,
    isWebchatNode: true,
    isTellusNode: true,
  },
  [NodeDesignType.TellusGetCustomerInformationVrf]: {
    markerColor: '#16BE57',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusGetCustomerInformationVrf,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.TellusDuplicateVrfClaimCheck]: {
    markerColor: '#b4a7d6',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.TellusDuplicateVrfClaimCheck,
    isWebchatNode: false,
    isTellusNode: true,
  },
  [NodeDesignType.GetUserWorkshopRoles]: {
    markerColor: '#b4a7d6',
    icon: getUserPoliciesIcon as string,
    invertedIcon: getUserPoliciesInvertedIcon as string,
    variation: nodeVariation.SERVICE,
    type: NodeDesignType.GetUserWorkshopRoles,
    isWebchatNode: false,
    isTellusNode: true,
  },
};
