import {
  BaseNodeProperty,
  Common_SendDataCompassNode,
  Denmark_Motor_SendDataNode,
  Denmark_PersonalInjury_SendDataNode,
  Denmark_Property_SendDataNode,
  Finland_Animal_SendDataNode,
  Finland_Athletes_SendDataNode,
  Finland_HealthNavigator_SendDataNode,
  Finland_Motor_SendDataNode,
  Finland_MTPL_SendDataNode,
  Finland_Into_SendDataNode,
  Finland_PersonalInjury_SendDataNode,
  Finland_Property_SendDataNode,
  Finland_Travel_SendDataNode,
  Finland_WorkersCompensation_SendDataNode,
  NodeDefinitionProperties,
  NodeDefinitionProperty,
  NodeParamProperty,
  NodeProperty,
  Norway_Animal_SendDataNode,
  Norway_CarGlass_SendDataNode,
  Norway_Expatriates_SendDataNode,
  Norway_Motor_SendDataNode,
  Norway_Motor_Commercial_SendDataNode,
  Norway_PersonalInjury_Commercial_SendDataNode,
  Norway_Property_Private_SendDataNode,
  Norway_Travel_SendDataNode,
  Sweden_FollowUp_SendDataNode,
  Sweden_Motor_SendDataNode,
  Sweden_PersonalInjury_SendDataNode,
  Sweden_Property_SendDataNode,
  Denmark_Commercial_Travel_SendDataNode,
  Finland_Boat_SendDataNode,
  Finland_Forest_SendDataNode,
  Common_Motor_SendDataNode,
  Finland_Motor_Commercial_SendDataNode
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SendDataLayout extends BaseLayout {
  constructor(
    node:
      | Common_SendDataCompassNode
      | Denmark_Motor_SendDataNode
      | Denmark_PersonalInjury_SendDataNode
      | Denmark_Property_SendDataNode
      | Denmark_Commercial_Travel_SendDataNode
      | Finland_Animal_SendDataNode
      | Finland_Athletes_SendDataNode
      | Finland_HealthNavigator_SendDataNode
      | Finland_Motor_SendDataNode
      | Finland_MTPL_SendDataNode
      | Finland_Into_SendDataNode
      | Finland_PersonalInjury_SendDataNode
      | Finland_Property_SendDataNode
      | Finland_Travel_SendDataNode
      | Finland_WorkersCompensation_SendDataNode
      | Finland_Boat_SendDataNode
      | Finland_Forest_SendDataNode
      | Norway_Animal_SendDataNode
      | Norway_CarGlass_SendDataNode
      | Norway_Expatriates_SendDataNode
      | Norway_Motor_SendDataNode
      | Norway_Motor_Commercial_SendDataNode
      | Norway_PersonalInjury_Commercial_SendDataNode
      | Norway_Travel_SendDataNode
      | Norway_Property_Private_SendDataNode
      | Sweden_FollowUp_SendDataNode
      | Sweden_Motor_SendDataNode
      | Sweden_PersonalInjury_SendDataNode
      | Sweden_Property_SendDataNode
      | Common_Motor_SendDataNode
      | Finland_Motor_Commercial_SendDataNode
  ) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapSendDataProps();
  }

  mapSendDataProps = (): void => {
    const sendDataGroup = this.propertiesTab.addGroup();
    const excludeBaseNodeProperties = Object.keys(BaseNodeProperty);

    const addSendDataProperties = (properties: NodeDefinitionProperties) =>
      Object.entries(properties)
        .filter(
          ([propertyKey, propertyValue]) =>
            !this.mappedFields?.includes(
              propertyKey as NodeProperty | BaseNodeProperty
            ) &&
            propertyValue &&
            !excludeBaseNodeProperties.includes(propertyKey as BaseNodeProperty)
        )
        .forEach(([_propertyKey, _propertyValue]) => {
          const propertyKey = _propertyKey as NodeProperty | BaseNodeProperty;
          const propertyValue = _propertyValue as
            | NodeDefinitionProperty
            | NodeParamProperty;
          sendDataGroup.addTextField(
            {
              fieldName: propertyKey,
              label: propertyKey,
              value: propertyValue.value || propertyValue.values?.join() || '',
            },
            this.mappedFields
          );
        });

    this.addSectionIdField();
    addSendDataProperties(this.node.properties);
  };
}

export default SendDataLayout;
